import React, { useEffect, useReducer, useState } from 'react';
import { withTranslation } from 'react-i18next';
import {
  Form,
  Modal,
  ModalBody,
  ModalHeader,
  Table,
} from 'reactstrap';

import { useFormik } from 'formik';
import Wizard from '../../Components/Common/Wizard';
import CsvImporter from '../../Components/Common/CsvImporter';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import {
  batchImportIncentive,
  batchImportWallet,
  getAllIncentiveGroups,
} from '../../slices/thunks';
import { currencyFieldToNumber } from '../../helpers/functions';
import { removeBomFromBinaryFile } from '../../helpers/encode_file';

const initialValues = {
  fields: undefined,
  rows: 0,
};

const WalletsImporter = ({ isOpen, toggle, onSuccess, t, tReady }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const dispatch = useDispatch();

  const getAllGroups = async () => {
    const action = await dispatch(
      getAllIncentiveGroups({
        filters: {
          type: { in: ['user'] },
        },
      })
    );

    if (!action.payload || action.error) {
      return;
    }

   
  };

  const [payload, setPayload] = useReducer(
    (acc, item) => ({ ...acc, ...item }),
    initialValues
  );

  useEffect(() => {
    getAllGroups();
  }, [isOpen]);


  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: async () => {
      setIsLoading(true);
  
      
      try {
        const cleanFile = await removeBomFromBinaryFile(payload.file);
  
        const {
          columnFields,
          preview,
        } = payload;
  
        const configurationParse = columnFields.reduce((currentvalue, value, index) => {
          const column = preview.columns.find((val) => val.index === index);
          return {
            ...currentvalue,
            [value || '']: { column: column?.header },
          };
        }, {});
  
        const data = {
          file: cleanFile,
          configuration_parse: JSON.stringify(configurationParse),
        };
  
        const action = await dispatch(
          batchImportWallet({
            ...data,
            successMessage: t('import-success'),
            errorMessage: t('import-error'),
          })
        );
  
        if (!action.payload.error) {
          resetState();
          onSuccess();
        }
      } finally {
        setIsLoading(false);
      }
    },
  });
  

  const resetState = () => {
    setTimeout(() => {
      setActiveTab(1);
      setPayload(initialValues);
      formik.resetForm();
    }, 1000);
    toggle();
  };

  return (
    <>
      <Modal
        centered
        isOpen={isOpen}
        size={activeTab >= 2 ? 'lg' : 'xl'}
        style={{ transition: 'all 0.2s ease' }}
        toggle={resetState}
        id="showModal"
      >
        <ModalHeader className="bg-info-subtle p-3" toggle={toggle}>
          {tReady && t('wallet-import-modal-heading')}
        </ModalHeader>

        <Form
          className="tablelist-form"
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
            return false;
          }}
        >
          <ModalBody>
            <Wizard
              showSteps
              activeTab={activeTab}
              tabs={[
                {
                  label: tReady && t('incentive-import-modal-step1-heading'),
                  component: (
                    <>
                      <h6>
                        {tReady && t('incentive-import-modal-step1-heading')}
                      </h6>
                      <p className="d-w-100">
                        {tReady &&
													t('wallet-import-modal-step1-description')}
                      </p>

                      <CsvImporter
                        onClose={() => setActiveTab(2)}
                        onComplete={(data) => setPayload({ ...data })}
                        dataHandler={(raw) => {
                          const rows = raw?.reduce(
                            (acc, i) => acc + i,
                            payload.rows
                          );
                          setPayload({ rows });
                        }}
                        fields={[
                          {
                            name: 'document',
                            label: t('import-document-prop'),
                          },
                          {
                            name: 'name',
                            label: t('import-name-prop'),
                            optional: true,
                          },
                          {
                            name: 'email',
                            label: t('import-email-prop'),
                            optional: true,
                          },
                          {
                            name: 'birthdate',
                            label: t('import-birthdate-prop'),
                            optional: true,
                          },
                          {
                            name: 'cellphone',
                            label: t('import-cellphone-prop'),
                            optional: true,
                          },
                        ]}
                      />
                    </>
                  ),
                },
                {
                  label: tReady && t('incentive-import-modal-step3-heading'),
                  component: (
                    <>
                      <h6>
                        {tReady && t('incentive-import-modal-step3-heading')}
                      </h6>
                      <p className="d-w-100">
                        {tReady &&
													t('incentive-import-modal-step3-description')}
                      </p>

                      <Table bordered striped>
                        <tbody>
                          <tr>
                            <th style={{ width: '30%' }} scope="row">
                              {t('wallets-import-review-amount')}
                            </th>
                            <td>
                              {payload?.rows} {t('items')}
                            </td>
                          </tr>
                         
                        </tbody>
                      </Table>

                      <div className="hstack gap-2 justify-content-end">
                        <button
                          type="button"
                          className="btn btn-light"
                          onClick={resetState}
                          disabled={isLoading}
                        >
                          {t('close')}
                        </button>

                        <button
                          type="submit"
                          className="btn btn-success"
                          disabled={isLoading}
                        >
                          {t('confirm')}
                        </button>
                      </div>
                    </>
                  ),
                },
              ]}
            />
          </ModalBody>
          
        </Form>
      </Modal>
    </>
  );
};

export default withTranslation()(WalletsImporter);
