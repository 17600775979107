import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getCustomers as getCustomersApi,
  getFilters as getFiltersApi,
  createCustomer as createCustomerApi,
  updateCustomer as updateCustomerApi,
  bulkUpdateCustomerWalletStatus as bulkUpdateCustomerWalletStatusApi,
  batchUpdateCustomerWalletStatus as batchUpdateCustomerWalletStatusApi,
  batchImportIncentive as batchImportIncentiveApi,
  getCustomer as getCustomerApi,
  getCustomerStatement as getCustomerStatementApi,
  getCustomerOperations as getCustomerOperationsApi,
  getCustomerExpirations as getCustomerExpirationsApi,
  sendToken as sendTokenApi,
  validateToken as validateTokenApi,
} from '../../helpers/bff_helper';

import { toast } from 'react-toastify';

export const getCustomers = createAsyncThunk(
  'customers/getCustomers',
  async (params) => {
    params.order = 'desc';
    params.page = params.page || 1;
    try {
      const response = getCustomersApi(params);

      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getFilters = createAsyncThunk('customers/getFilters', async () => {
  try {
    return getFiltersApi();
  } catch (error) {
    return error;
  }
});

export const createCustomer = createAsyncThunk(
  'customers/createCustomer',
  async ({
    customer,
    origin,
    successMessage,
    conflictMessage,
    errorMessage,
  }) => {
    try {
      const response = await createCustomerApi({ ...customer, origin });
      toast.success(successMessage, { autoClose: 5000 });

      return response;
    } catch (error) {
      const response = {
        error: true,
        message: error,
      };

      if (error === 'conflict') {
        toast.error(conflictMessage, { autoClose: 5000 });
        return response;
      }

      toast.error(errorMessage, { autoClose: 5000 });
      return response;
    }
  }
);

export const updateCustomer = createAsyncThunk(
  'customers/updateCustomer',
  async ({ document, customer, successMessage, errorMessage }) => {
    try {
      const response = await updateCustomerApi(document, customer);
      toast.success(successMessage, { autoClose: 5000 });

      return response;
    } catch (error) {
      const response = {
        error: true,
        message: error,
      };

      toast.error(errorMessage, { autoClose: 5000 });
      return response;
    }
  }
);

export const bulkUpdateCustomerWalletStatus = createAsyncThunk(
  'customers/bulkUpdateCustomerWalletStatus',
  async ({ customers_wallets, status, successMessage, errorMessage }) => {
    try {
      await bulkUpdateCustomerWalletStatusApi(customers_wallets, status);
      toast.success(successMessage, { autoClose: 5000 });
      return true;
    } catch (error) {
      const response = {
        error: true,
        message: error,
      };

      toast.error(errorMessage, { autoClose: 5000 });
      return response;
    }
  }
);

export const batchUpdateCustomerWalletStatus = createAsyncThunk(
  'customers/batchUpdateCustomerWalletStatus',
  async ({ successMessage, errorMessage, ...data }) => {
    try {
      await batchUpdateCustomerWalletStatusApi(data);
      toast.success(successMessage, { autoClose: 5000 });
      return true;
    } catch (error) {
      const response = {
        error: true,
        message: error,
      };

      toast.error(errorMessage, { autoClose: 5000 });
      return response;
    }
  }
);

export const batchImportWallet = createAsyncThunk(
  'customers/batchImportWallet',
  async (params) => {
    try {
      const response = await batchImportIncentiveApi(params);

      toast.success(params?.successMessage);

      return JSON.stringify(response);
    } catch (error) {
      toast.error(params?.errorMessage);
      return error;
    }
  }
);


export const getCustomer = createAsyncThunk(
  'customers/getCustomer',
  async ({ document, params }) => {
    try {
      const response = getCustomerApi(document, params);

      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  }
);

export const getCustomerStatement = createAsyncThunk(
  'customers/getCustomerStatement',
  async ({ document, params }) => {
    try {
      const response = getCustomerStatementApi(document, params);

      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  }
);

export const getCustomerOperations = createAsyncThunk(
  'customers/getCustomerOperations',
  async ({ document, params }) => {
    try {
      const response = getCustomerOperationsApi(document, params);

      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  }
);

export const getCustomerExpirations = createAsyncThunk(
  'customers/getCustomerExpirations',
  async ({ document, params }) => {
    try {
      const response = getCustomerExpirationsApi(document, params);

      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  }
);

export const sendToken = createAsyncThunk(
  'customers/sendToken',
  async ({ document, params, errorMessage }) => {
    try {
      const response = sendTokenApi(document, params);

      return response;
    } catch (error) {
      toast.error(errorMessage, { autoClose: 5000 });

      return {
        error: true,
        message: error,
      };
    }
  }
);

export const validateToken = createAsyncThunk(
  'customers/validateToken',
  async ({ document, params }) => {
    try {
      const response = validateTokenApi(document, params);

      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  }
);
