import React, { useEffect, useMemo, useState, useReducer } from 'react';
import { withTranslation } from 'react-i18next';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Spinner,
} from 'reactstrap';
import Breadcrumb from '../../../Components/Common/BreadCrumb';
import ExportReport from '../../../Components/Common/ExportReport';
import TableContainer from '../../../Components/Common/TableContainer';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { getIncentiveGroups } from '../../../slices/thunks';
import { formatCurrency } from '../../../helpers/format';
import { Link } from 'react-router-dom';
import CreateIncentiveGroup from './CreateGroup';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CreateIncentive from './CreateIncentive';
import { Can } from '../../../helpers/casl';
import IncentiveImporter from './IncentiveImporter';
import IncentiveDropdown from './components/IncentiveDropdown';
import DeleteGroup from './components/DeleteGroup';

const Incentive = (props) => {
  document.title = 'Grupo de incentivos | Opencashback';

  const [createGroupModal, setCreateGroupModal] = useState(false);
  const [createIncentiveModal, setCreateIncentiveModal] = useState(false);
  const [showCsvImportIncentiveModal, setCsvImportIncentiveModal] = useState(false);
  const [selectedIncentive, setSelectedIncentive] = useState(null);

  const toggleCreateGroup = () => {
    setCreateGroupModal(!createGroupModal);
  };

  const toggleCreateIncentive = () => {
    setCreateIncentiveModal(!createIncentiveModal);
  };

  const dispatch = useDispatch();
  const groupsSelector = createSelector(
    (state) => state.Incentive.groups,
    (groups) => groups
  );

  const metaSeletor = createSelector(
    (state) => state.Incentive.meta,
    (meta) => meta
  );

  const isLoadingSelector = createSelector(
    (state) => state.Incentive.isLoading,
    (isLoading) => isLoading
  );

  const groups = useSelector(groupsSelector);
  const meta = useSelector(metaSeletor);
  const isLoading = useSelector(isLoadingSelector);

  const [page, setPage] = useState(1);

  const getData = () => {
    const params = {
      page,
      filters: {
        type: { in: ['user'] },
      },
    };

    dispatch(getIncentiveGroups(params));
  };

  useEffect(() => {
    getData();
  }, [dispatch, page]);

  const [modals, dispatchModal] = useReducer(
    (state, action) => {
      switch (action.type) {
        case 'toggleDeleteGroupModal':
          return {
            ...state,
            isDeleteGroupModalOpened: !state?.isDeleteGroupModalOpened,
          };
        default:
          throw Error('Unknown modal action.');
      }
    },
    {
      isDeleteGroupModalOpened: false,
    }
  );

  const columns = useMemo(
    () => [
      {
        Header: props.t('group-name'),
        accessor: 'group-name',
        filterable: false,
        Cell: (info) => info.row.original.name,
        reportProps: {
          accessor: 'name',
        },
      },
      {
        Header: props.t('credit-wallets'),
        accessor: 'credit-wallets',
        filterable: false,
        Cell: (info) => info.row.original.distinct_wallets_count,
        reportProps: {
          accessor: 'distinct_wallets_count',
        },
      },
      {
        Header: props.t('credit-total'),
        accessor: 'credit-total',
        filterable: false,
        Cell: (info) =>
          formatCurrency(info.row.original.total_incentives_amount),
        reportProps: {
          accessor: 'total_incentives_amount',
          formatter: {
            type: 'currency',
            properties: { currency: 'BRL', centsToCurrency: true },
          },
        },
      },
      {
        Header: props.t('orders-generated'),
        accessor: 'orders-generated',
        filterable: false,
        Cell: (info) => info.row.original.total_orders_related,
        reportProps: {
          accessor: 'total_orders_related',
        },
      },
      {
        Header: props.t('used-credit'),
        accessor: 'used-credit',
        filterable: false,
        Cell: (info) => formatCurrency(info.row.original.total_credit_redeemed),
        reportProps: {
          accessor: 'total_credit_redeemed',
          formatter: {
            type: 'currency',
            properties: { currency: 'BRL', centsToCurrency: true },
          },
        },
      },
      {
        Header: props.t('will-expire-credit'),
        accessor: 'will-expire-credit',
        filterable: false,
        Cell: (info) =>
          formatCurrency(info.row.original.total_credit_to_expire),
        reportProps: {
          accessor: 'total_credit_to_expire',
          formatter: {
            type: 'currency',
            properties: { currency: 'BRL', centsToCurrency: true },
          },
        },
      },
      {
        Header: props.t('expired-credit'),
        accessor: 'expired-credit',
        filterable: false,
        Cell: (info) => formatCurrency(info.row.original.total_credit_expired),
        reportProps: {
          accessor: 'total_credit_expired',
          formatter: {
            type: 'currency',
            properties: { currency: 'BRL', centsToCurrency: true },
          },
        },
      },
      {
        Header: props.t('incremental-billing-generated'),
        accessor: 'incremental-billing-generated',
        filterable: false,
        Cell: (info) => formatCurrency(info.row.original.total_orders_amount),
        reportProps: {
          accessor: 'total_orders_amount',
          formatter: {
            type: 'currency',
            properties: { currency: 'BRL', centsToCurrency: true },
          },
        },
      },
      {
        Header: props.t('discount-in-incremental-billing'),
        accessor: 'discount-in-incremental-billing',
        filterable: false,
        Cell: (info) => {
          const raw = info.row.original.discount_in_total_orders_amount;

          let value = Math.round(raw * 100);
          let color = '#212529';

          const low = value >= 0.1 && value <= 10;
          const regular = value >= 11 && value <= 40;
          const good = value >= 41 && value <= 70;
          const high = value >= 71;

          if (low) color = 'green';
          if (regular) color = 'blue';
          if (good) color = 'orange';
          if (high) color = 'tomato';

          return (
            <span className="fw-semibold" style={{ color }}>
              {value > 100 ? 100 : value}%
            </span>
          );
        },
        reportProps: {
          accessor: 'discount_in_total_orders_amount',
          formatter: {
            type: 'decimal_percentage',
            properties: { value: 0 },
          },
        },
      },
      {
        Header: props.t('engagement'),
        accessor: 'engagement',
        filterable: false,
        Cell: (info) => {
          const raw = info.row.original.engagement_percentage;

          let value = Math.round(raw * 100);
          let color = '#212529';

          const low = value >= 0.1 && value <= 10;
          const regular = value >= 11 && value <= 40;
          const good = value >= 41 && value <= 70;
          const high = value >= 71;

          if (low) color = 'tomato';
          if (regular) color = 'orange';
          if (good) color = 'blue';
          if (high) color = 'green';

          return (
            <span className="fw-semibold" style={{ color }}>
              {value > 100 ? 100 : value}%
            </span>
          );
        },
        reportProps: {
          accessor: 'engagement_percentage',
          formatter: {
            type: 'decimal_percentage',
            properties: { value: 0 },
          },
        },
      },
      {
        Header: props.t('actions'),
        Cell: (props) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <Can I="update" a="incentives">
                <li className="list-inline-item" title="Edit">
                  <IncentiveDropdown
                    incentive={props.row.original}
                    onClickDelete={() => {
                      setSelectedIncentive(props.row.original);
                      dispatchModal({ type: 'toggleDeleteGroupModal' });
                    }}
                  />
                </li>
              </Can>
            </ul>
          );
        },
        reportProps: {
          exclude: true,
        },
      },
      // {
      //   Header: props.t('actions'),
      //   Cell: (cellProps) => {
      //     return (
      //       <ul className="list-inline hstack gap-2 mb-0">
      //         <li className="list-inline-item" title="View">
      //           <Link to={`/incentivos/grupos/${cellProps.row.original.id}`}>
      //             <i className="ri-eye-fill align-bottom text-muted"></i>
      //           </Link>
      //         </li>
      //         <li className="list-inline-item" title="Edit">
      //           {/* <Link className="edit-item-btn"
      //             onClick={() => { setEditWalletData(cellProps.row.original); setEditWalletModal(true) }}
      //           >
      //             <i className="ri-pencil-fill align-bottom text-muted"></i>
      //           </Link> */}
      //         </li>
      //       </ul>
      //     );
      //   },
      // },
    ],
    []
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Grupo de incentivos" pageTitle="Ferramentas" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <Row>
                    <Col sm={1}>{isLoading && <Spinner color="primary" />}</Col>
                    <div className="col-sm-auto ms-auto">
                      <div className="hstack gap-2">
                        {/* <button type="button" className="btn btn-secondary" onClick={() => {}}>
                          <i className="ri-filter-3-line align-bottom me-1"></i>{" "}
                          {props.t('filters')}
                        </button> */}
                        <Can I="create" a="incentives">
                          <button
                            type="button"
                            className="btn btn-secondary add-btn"
                            id="manage-import-incentives-btn"
                            onClick={() =>
                              setCsvImportIncentiveModal(
                                !showCsvImportIncentiveModal
                              )
                            }
                          >
                            <i className="ri-file-upload-line align-bottom me-1"></i>
                            {props.t('incentive-import-button')}
                          </button>

                          <button
                            type="button"
                            className="btn btn-secondary add-btn"
                            id="create-btn"
                            onClick={toggleCreateIncentive}
                          >
                            <i className="ri-add-line align-bottom me-1"></i>
                            {props.t('new-incentive')}
                          </button>
                          <button
                            type="button"
                            className="btn btn-secondary add-btn"
                            id="create-btn"
                            onClick={toggleCreateGroup}
                          >
                            <i className="ri-add-line align-bottom me-1"></i>
                            {props.t('new-incentive-group')}
                          </button>
                        </Can>

                        <ExportReport
                          pages={meta?.pages}
                          disabled={isLoading || !groups?.length}
                          filename="Relatório OCK: Grupo De Incentivos"
                          service="incentives/groups"
                          columns={columns}
                          data={{}}
                          filters={{}}
                        />
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody className="pt-0">
                  <Can I="read" a="incentives">
                    <div>
                      <TableContainer
                        isLoading={isLoading}
                        columns={columns}
                        data={groups || []}
                        activePage={page}
                        pageSize={meta?.take || 10}
                        totalPages={meta?.pages || 0}
                        count={meta?.total || 0}
                        setPage={setPage}
                        className="custom-header-css"
                        divClass="table-responsive table-card mb-0"
                        tableClass="align-middle table-nowrap"
                        theadClass="table-light"
                      />
                    </div>
                  </Can>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <CreateIncentiveGroup
        isOpen={createGroupModal}
        toggle={toggleCreateGroup}
        onSuccess={() => getData()}
      />

      <CreateIncentive
        isOpen={createIncentiveModal}
        toggle={toggleCreateIncentive}
        onSuccess={() => getData()}
      />

      <IncentiveImporter
        isOpen={showCsvImportIncentiveModal}
        toggle={() => setCsvImportIncentiveModal(!showCsvImportIncentiveModal)}
        onSuccess={() => null}
      />

      <DeleteGroup
        isOpen={modals.isDeleteGroupModalOpened}
        toggle={() => dispatchModal({ type: 'toggleDeleteGroupModal' })}
        onSuccess={() => getData()}
        incentive={selectedIncentive}
      />

      <ToastContainer position="top-center" closeButton={false} limit={1} />
    </React.Fragment>
  );
};

export default withTranslation()(Incentive);
